/* @import url("https://fonts.googleapis.com/css2?family=Athiti:wght@400;500;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: "Athiti", sans-serif; */
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .btn-primary {
    @apply px-5 mt-2 py-1.5 text-sm bg-primary text-white font-[500] rounded-lg border-[1px] border-primary;
  }

  .btn-secondary {
    @apply px-5 mt-2 py-1.5 text-sm text-gray-500 font-[500] rounded-lg border-[1px] border-gray-400;
  }

  .title {
    @apply text-2xl font-[600] text-[#101828] ease-in duration-300;
  }
}

.card {
  background-color: rgba(35, 85, 67, 0.5);
}
